import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { Platforms } from "../../components/DropDowns/faq.component";

/**
 * Custom Hooks
 */
import useDropdown from "../../customHooks/use-dropdown";

const HeaderDropdown2 = () => {
  const node = useRef();

  const [dropdown, toggleDropDown] = useDropdown(node);
  const onClick = () => toggleDropDown();

  const dropdownClass = "dropdown-heading";

  return (
    <>
    <span className="header-mobile">
      <Platforms  />
    </span>

    <div ref={node} className="dropdown">
      <span className="header-desktop">
      <button
        className="btn btn--small btn--uppercase header-navbar__services"
        onClick={onClick}
      >
        SYNOLLO USERS
      </button>

      <ul className="style-dropdown">
        <Link to="/investor">
          <li className="dropdown-list">Investor</li>
        </Link>
        <Link to="/developer">
          <li className="dropdown-list">Developers</li>
        </Link>
        <Link to="/asset_owner">
          <li className="dropdown-list">Asset Owners</li>
        </Link>
        <Link to="/distressed_sale">
          <li className="dropdown-list">Distressed Sales</li>
        </Link>
        <Link to="/dsr">
          <li className="dropdown-list">Direct Sales Reps</li>
        </Link>
        <Link to="/easy_rent">
          <li className="dropdown-list">EasyRent</li>
        </Link>
      </ul>
      </span>
    </div>
    </>
  );
};

export default HeaderDropdown2;
