import axios from "axios";


export const API = axios.create({
  
  // baseURL: "http://localhost:4000/"
  baseURL:  process.env.NODE_ENV === "development" ? "http://localhost:4000/v1" : "https://synollo.xyz/v1"
  // baseURL: "https://synolo.herokuapp.com/v1"

});

API.interceptors.request.use(function (config) {
  const token = localStorage.getItem('user_acess_passcode');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});