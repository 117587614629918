import React from 'react'

const Loader= () => {
  return (
    <div className="loader-wrapper">
      <div className="loader"></div>
    </div>
    
  )
}


export const LoaderTiny= () => {
  return (
    <div className="loadertiny"></div>
  )
}

export const LoaderCustom = () => {
  return (
    <div className="loadercustom"></div>
  )
}

export default Loader
