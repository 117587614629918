import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { Professional } from "./faq.component";
import avatar from '../../assets/images/Avatar/avatar3.png'
/**
 * Custom Hooks
 */
import useDropdown from "../../customHooks/use-dropdown";
import { LogoutNav } from '../Auth/Logout/Logout';
import { API } from '../../helpers/api.helpers';

const AuthDropdown = ({ profile }) => {
  const node = useRef();

  const [dropdown, toggleDropDown] = useDropdown(node);
  const onClick = () => toggleDropDown();

  const dropdownClass = "dropdown-heading";


  const dashboard = () => {

    if(profile === "super") {
      return `/dashboard/superadmin`
    } else if((profile === "privileged" || profile === "vet" || profile === "post")) {
      return `/dashboard/admin`
    } else {
      return `/dashboard/${profile}`
    }
  }

  const logout = async () => {

    try {

      const response = await API.post("/logout");
      if(response.status === 200) {

        localStorage.clear()
        window.location.href = "/"
      }
    }  catch (err) {
      console.log("the error: ")
    }
  }

  return (
    <>
    <span className="header-mobile">
      <span className="header-btns">
        <Link to={dashboard}>
          <button className="header-navbar__btn btn btn--small btn--uppercase header-navbar__aboutus">
            Dashboard
          </button>
        </Link>
      </span>

      <span className="header-btns">
        <button onClick={logout} className="header-navbar__btn btn btn--small btn--uppercase  btn--round btn--orange btn--focus btn--black nav-btn">
          Logout
        </button>
      </span>
    </span>

    <div ref={node} className="dropdown-auth">
    <span className="header-desktop">
      <img src={avatar} alt="the avatar" className="avatar" />
      <ul className="others style-dropdown">
        <Link to={dashboard}>
          <button className="dropdown-list">Dashboard</button>
        </Link>
        <LogoutNav />
      </ul>
      </span>
    </div>
    </>
  );
};

export default AuthDropdown;
