import React, { useContext } from "react";
import { Redirect, Route, useLocation, useHistory } from "react-router-dom";
import { PRIVILEGED, POST, SUPERADMIN, VET } from '../constants'
import { AuthContext } from "./authContext";


export const ProtectedRoute = ({ component: Component, ...routeProps }) => {

  // console.log("this is the auth that is called")
  const { authenticated, authUser } = useContext(AuthContext);

  let location = useLocation();
  const history = useHistory();

  // console.log(Component);
  return (
    <Route
      render={props =>
        (authenticated && location.pathname.includes(authUser.role)) || (authenticated && (authUser.role === PRIVILEGED || authUser.role === POST)) ? <Component {...props} /> 
        : authenticated && !location.pathname.includes(authUser.role) && (authUser.role === PRIVILEGED || authUser.role === POST) ? history.goBack() : <Redirect to="/login" />
      }
      {...routeProps}
    />
  );

  // return (
  //   <Route
  //     render={props =>
  //       authenticated  ? <Component {...props} />  : <Redirect to="/login" />
  //     }
  //     {...routeProps}
  //   />
  // );
};

// export const xAdminRoute = ({ component: Component, ...routeProps }) => {

//   const { authenticated, authUser } = useContext(AuthContext);

//   console.log(Component);
//   return (
//     <Route
//       render={props =>
//         authenticated && (authUser.role === "Admin") ? <Component {...props} /> : <Redirect to="/admin/login" />
//       }
//       {...routeProps}
//     />
//   );
// };


export const AdminRoute = ({ component: Component, ...routeProps }) => {

  const { authenticated, authUser } = useContext(AuthContext);

  console.log(Component);
  return (
    <Route
      render={props =>
        authenticated && (authUser.role === PRIVILEGED || authUser.role === VET || authUser.role === POST ) ? <Component {...props} /> : <Redirect to="/admin/login" />
      }
      {...routeProps}
    />
  );
};


export const PostAdminRoute = ({ component: Component, ...routeProps }) => {
  const { authenticated, authUser } = useContext(AuthContext);

  console.log(Component);
  return (
    <Route
      render={props =>
        authenticated && authUser.role === "post" ? <Component {...props} /> : <Redirect to="/admin/login" />
      }
      {...routeProps}
    />
  );
};


export const VetAdminRoute = ({ component: Component, ...routeProps }) => {
  const { authenticated, authUser } = useContext(AuthContext);

  console.log(Component);
  return (
    <Route
      render={props =>
        authenticated && authUser.role === "vet" ? <Component {...props} /> : <Redirect to="/admin/login" />
      }
      {...routeProps}
    />
  );
};

export const SuperAdminRoute = ({ component: Component, ...routeProps }) => {

  const { authenticated, authUser } = useContext(AuthContext);

  return (
    <Route
      render={props =>
        authenticated && authUser.role === SUPERADMIN ? <Component {...props} /> : <Redirect to="/superadmin/login" />
      }
      {...routeProps}
    />
  );
};



