import React, { useRef } from "react";
import { KnowUs } from "../../components/DropDowns/faq.component";


/**
 * Custom Hooks
 */
import useDropdown from "../../customHooks/use-dropdown";
import { Link } from "react-router-dom";

const HeaderDropdown4 = () => {
  const node = useRef();

  const [dropdown, toggleDropDown] = useDropdown(node);
  const onClick = () => toggleDropDown();

  const dropdownClass = "dropdown-heading";

  return (
    <>
      <span className="header-mobile">
        <KnowUs  />
      </span>
      
      <div ref={node} className="dropdown">
        <span className="header-desktop">
        <button
          className="btn btn--small btn--uppercase header-navbar__services"
        >
          Know Us
        </button>      
        <ul className="others style-dropdown">
          <Link to="/about">
            <li className="dropdown-list">About Us</li>
          </Link>
          <Link to="/partners">
            <li className="dropdown-list">Our Partners</li>
          </Link>
        </ul>
        </span>
      </div>
    </>
  );
};

export default HeaderDropdown4;
