import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";


/**
 * Custom Hooks
 */
// import useToggle from "../../customHooks/useToggle";

/**
 * Images
 */
import logo from "../../assets/images/logo.svg";

/**
 * Icons
 */
import hambuger from "../../assets/icons/hambuger.svg";
import close from "../../assets/icons/cross.svg";

/**
 * Custom Component
 */
import HeaderDropdown from "../DropDowns/headerDropdown1.jsx";
import HeaderDropdown2 from "../DropDowns/headerDropdown2";
import HeaderDropdown3 from "../DropDowns/headerDropdown3";
import HeaderDropdown4 from "../DropDowns/headerDropdown4";
import AuthDropdown from '../DropDowns/authDropdown';


const Navigation2 = () => {


  const [data, setData] = useState('')
  const [role, setRole] = useState('')
  const [hambug, setHambug] = useState(false);
  const handleHambug = () => setHambug(!hambug);

  useEffect(() => {
    const getToken = () => {
      const auth = localStorage.getItem('user_acess_passcode')
      const role = localStorage.getItem('user_acess-role')

      setData(auth)
      setRole(role)
    }
    getToken()
  }, []);

  return (
    <>
      <header className="header-nav section-wrap header-nav-2">
        <Link to="/">
          <div className="header-logo">
            <img src={logo} alt="synolo logo" className="logo" />
          </div>
        </Link>

        <img
          src={hambuger}
          alt="hambuger"
          className="hambuger"
          onClick={() => handleHambug()}
        />
        <nav
          className={hambug ? "header-navbar hambuger-nav" : "header-navbar"}
        >

          <HeaderDropdown4 />
          <HeaderDropdown2 />
          <HeaderDropdown />
          <HeaderDropdown3 />

          <Link to="/properties/public">
            <button className="header-navbar__btn btn btn--small btn--uppercase btn--uppercase btn--black header-navbar__aboutus">
              Browse Properties
            </button>
          </Link>

          {!data ? (
            <>
            <span className="header-btns">
              <Link to="/login">
                <button className="header-navbar__btn btn btn--small btn--uppercase  btn--round btn--border btn--orange btn--hover btn--focus btn--black nav-btn">
                  Login
                </button>
              </Link>
            </span>
            <span className="header-btns">
            <Link to="/register">
              <button className="header-navbar__btn btn btn--small btn--uppercase btn--round btn--orange btn--focus btn--black nav-btn">
                Signup
              </button>
            </Link>
            </span>
            </>
          ) : (
            <AuthDropdown profile={role} />
          )}
          
          <img
            src={close}
            alt="close"
            className="hambuger-close"
            onClick={() => handleHambug()}
          />
        </nav>
      </header>
    </>
  );
};

export default Navigation2;
