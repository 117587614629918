import React from 'react'
import { API } from "../../../helpers/api.helpers";


export const Logout = () => {

  return (
    <>
      <button onClick={logout} className="sidebar-nav-btn">
        Logout
      </button>
    </>
  )
}

export const LogoutNav = () => {

  return (
    <>
      <button onClick={logout} className="dropdown-list style-nav-logout">
        Logout
      </button>
    </>
  )
}

export const LogoutMobile = () => {

return (
    <>
      <button onClick={logout} className="header-navbar__btn btn btn--small btn--uppercase header-navbar__aboutus">
        Logout
      </button>
    </>
  ) 
}

// export const LogoutMobile = () => {

// return (
//     <>
//       <button onClick={logout} className="header-navbar__btn btn btn--small btn--uppercase header-navbar__aboutus">
//         Logout
//       </button>
//     </>
//   ) 
// }


const logout = async () => {

  try {
    const response = await API.post("/logout");
    if(response.status === 200) {

      localStorage.clear()
      window.location.href = "/"
    }
  }  catch (err) {
    console.log("this is the logout error: ", err)
  }
}



