import React, {useState} from 'react';

/**
 * Icons
 */
import dropdownIcon from '../../assets/icons/dropdown.svg';
import dropupIcon from '../../assets/icons/dropup.svg';

import { Link } from "react-router-dom";

const FAQDropdown = ({title, desc}) => {

  const [active, setActive] = useState("");

  function toggleActive() {
    setActive(active === "" ? "active" : "");
  }

  return (
    <div className={`section-wrap section-faq__card-nav card ${active}`} onClick={toggleActive}>
      <div className="section-faq__box">
        <h1 className="section-faq--title">{title}</h1>
        <img style={{padding: "10px"}} src={active === "" ? dropdownIcon : dropupIcon} alt="dropIcon" />
      </div>

      {active !== "" &&
        <div className="section-faq__content">
          <hr />
          <p style={{padding: "20px"}}>{desc}</p>
        </div>
      }
    </div>
  )
}

export const KnowUs = () => {

  const [active, setActive] = useState("");

  function toggleActive() {
    setActive(active === "" ? "active" : "");
  }

  return (
    <div className={`section-wrap section-faq__card-nav card ${active}`} onClick={toggleActive}>
      <div className="section-faq__box" style={{marginBottom: "10px"}}>
        <h1 className="section-faq--title-nav">KNOW US</h1>
        <span className="checkDrop">
          <i className={active === "" ? 'arrow-r' : 'arrow-d'}></i>
        </span>
      </div>

      {active !== "" ?
      <>
        <div className="section-faq__content-nav">
          <Link to="/about">
            <p>ABOUT US</p>
          </Link>
        </div>
        <hr />
        <div className="section-faq__content-nav">
          <Link to="/partners">
            <p>OUR PARTNERS</p> 
          </Link>
        </div>
        <hr />
      </>
      : false}
    </div>
  )
}

export const Platforms = () => {

  const [active, setActive] = useState("");

  function toggleActive() {
    setActive(active === "" ? "active" : "");
  }

  return (
    <div className={`section-wrap section-faq__card-nav card ${active}`} onClick={toggleActive}>
      <div className="section-faq__box" style={{marginBottom: "10px"}}>
        <h1 className="section-faq--title-nav">SYNOLLO USERS</h1>
        <span className="checkDrop">
          <i className={active === "" ? 'arrow-r' : 'arrow-d'}></i>
        </span>
      </div>

      {active !== "" ?
      <>
        <div className="section-faq__content-nav">
          <Link to="/investor/register">
            <p>INVESTORS</p>
          </Link>
        </div>
        <hr />
        <div className="section-faq__content-nav">
          <Link to="/developer/register">
            <p>DEVELOPERS</p> 
          </Link>
        </div>
        <hr />
        <div className="section-faq__content-nav">
          <Link to="/asset_owner/register">
            <p>ASSET OWNERS</p> 
          </Link>
        </div>
        <hr />
        <div className="section-faq__content-nav">
          <Link to="/distressed_sale/register">
            <p>DISTRESSED SALES</p> 
          </Link>
        </div>
        <hr />
        <div className="section-faq__content-nav">
          <Link to="/dsr/register">
            <p>DIRECT SALES REPS</p> 
          </Link>
        </div>
        <hr />
        <div className="section-faq__content-nav">
          <Link to="/easy_rent/register">
            <p>EASY RENT</p> 
          </Link>
        </div>
        <hr />
      </>
      : false}
    </div>
  )
}

export const Professional = () => {

  const [active, setActive] = useState("");

  function toggleActive() {
    setActive(active === "" ? "active" : "");
  }

  return (
    <div className={`section-wrap section-faq__card-nav card ${active}`} onClick={toggleActive}>
      <div className="section-faq__box" style={{marginBottom: "10px"}}>
        <h1 className="section-faq--title-prof">PROFESSIONAL SERVICE</h1>
        <span className="checkDrop">
          <i className={active === "" ? 'arrow-r' : 'arrow-d'}></i>
        </span>
      </div>

      {active !== "" ?
      <>
        <div className="section-faq__content-nav">
          <Link to="/legal-search">
            <p>PROPERTY SEARCH</p>
          </Link>
        </div>
        <hr />
        <div className="section-faq__content-nav">
          <Link to="/title">
            <p>TITLE PERFECTION</p> 
          </Link>
        </div>
        <hr />
      </>
      : false}
    </div>
  )
}


export const Research = () => {

  const [active, setActive] = useState("");

  function toggleActive() {
    setActive(active === "" ? "active" : "");
  }

  return (
    <div className={`section-wrap section-faq__card-nav card ${active}`} onClick={toggleActive} style={{paddingBottom: "37px"}}>
      <div className="section-faq__box">
        <h1 className="section-faq--title-prof">RESEARCH & ARTICLES</h1>
        <span className="checkDrop">
          <i className={active === "" ? 'arrow-r' : 'arrow-d'}></i>
        </span>
      </div>

      {active !== "" ?
      <>
        <div className="section-faq__content-nav">
          <Link to="/risks">
            <p>RISKS</p>
          </Link>
        </div>
        <hr />
      </>
      : false}
    </div>
  )
}

// export const AuthDrop = ({ dashboard }) => {

// const [active, setActive] = useState("");

// function toggleActive() {
//   setActive(active === "" ? "active" : "");
// }

// return (
//   <div className={`section-wrap section-faq__card-nav card ${active}`} onClick={toggleActive}>
//     <div className="section-faq__box" style={{marginBottom: "10px"}}>
//       <h1 className="section-faq--title-prof">
//         <img src={avatar} alt="the avatar" className="avatar" />
//       </h1>
//       <span className="checkDrop">
//         <i className={active === "" ? 'arrow-r' : 'arrow-d'}></i>
//       </span>
//     </div>

//     {active !== "" ?
//     <>
//       <div className="section-faq__content-nav">
//         <Link to={dashboard}>
//           <p>DASHBOARD</p>
//         </Link>
//       </div>
//       <hr />
//       <div className="section-faq__content-nav">
//         <Link to="">
//           <LogoutMobile />
//         </Link>
//       </div>
//       <hr />
//     </>
//     : false}
//   </div>
//   )
// }


export default FAQDropdown;