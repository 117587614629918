import React, { useState, useEffect } from "react";
import { useParams} from "react-router";
import { Link } from "react-router-dom";


/**
 * Helpers
 */
import { API } from "../../../helpers/api.helpers";
import { LoaderTiny } from "../../../components/Loader/loader.component";
import Navigation2 from "../../../components/Navigation/navigation-2.component";


const initialState = {
  newPassword: "",
  confirmPassword: "",
  token: "",
  email: "",
  profile: ""
}

const ResePassword = () => {

  console.log("we came to this now")

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [pass, setPass] = useState(initialState);
  const [submission, setSubmission] = useState(false);
  const [validate, setValidate] = useState(false);

  let { token, email, profile } = useParams();

  console.log('token, email, profile ', token, email, profile)

  useEffect(() => {
    validateInput();
  });

  const onSubmitReset = async (e) => {

    e.preventDefault()

    setSubmission(true)

    pass.token = token
    pass.email = email
    pass.profile = profile

    try {

      const res = await API.put("/profile/reset-password", pass);

      setSubmission(false)

      console.log("the res: ", res)
      if(res.status === 200) {
        setError("")
        setSuccess("Successfully reset password, you can proceed to login")
        setPass({})
      }
    } catch (err) {
      setSubmission(false)
      console.log(err)
      setError(err.response.data.error);
      setSuccess("")
    }
  };

  const handleChange = event => {

    setPass({
      ...pass,
      [event.target.name]: event.target.value
    });
  }

  const validateInput = () => {
   if (pass.newPassword === "" || pass.confirmPassword === "") {
      setValidate(true)
    } else {
      setValidate(false)
    }
  }

  const loginClick = () => {
    window.location.href = `/${profile}/login`
  }

  return (
      <div className="login">
       <header className="section register-header">
          <Navigation2 />
        </header>

      <div className="login-form">
        <div className="login-step--1">
          <h1 className="heading-primary login-heading">Reset Password</h1>
        </div>
        <form onSubmit={onSubmitReset}>
          <div className="register-form-box">
            <input
              type="password"
              name="newPassword"
              id="newPassword"
              className="register-form__input login-form__input"
              placeholder="New Password"
              required
              autoComplete="new-password"
              onChange={handleChange}
              value={pass.newPassword}
            />
            <input
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              className="register-form__input login-form__input"
              placeholder="Confirm Password"
              required
              autoComplete="new-password"
              onChange={handleChange}
              value={pass.confirmPassword}
            />
          </div>
          {error ? (
            <div className="alert slider alert-success">{error}</div>
          ) : (
            <></>
          )}

          {success ? (
            <div className="alert slider alert-success">{success}</div>
          ) : (
            <></>
          )}

          <button type="submit" className="btn btn--big btn--round btn--orange btn--shadow login-form__btn" disabled={validate}>
            {submission ? <LoaderTiny /> : "Submit"}
          </button>

          {success ? (
            <button className="btn--word login-password-reset" onClick={loginClick}>
              Login
            </button>
          ) : (
            <></>
          )}
        </form>
      </div>
    </div>
  );
};

export default ResePassword;
