export const PRIVILEGED = "privileged"

export const VET  = "vet"

export const POST = "post" 

export const SUPERADMIN = "super"

export const ASSETOWNER = "asset_owner"

export const DEVELOPER = "developer"

export const EASYRENT = "easy_rent"

export const DISTRESSED = "distressed_sale"

export const DSR = "dsr"

export const INVESTOR = "investor"

export const ADMINPROPERTY = "admin"

export const ADMINPUBLICPROPERTY = "admin_public"

export const PUBLICPUBLISHED = "public published"

export const BUYING = "buying"

export const UNPUBLISHED = "unpublished"

export const PUBLISHED = "published"

export const STRUCTURESUCCESS = "structuring success"

export const LOAN = "loan"

export const DEBITCARD = "debitcard"

export const CONSULTATION = "consultation"

export const SEARCHCONSULTATION = "consultation and legal search"

export const PENDING = "pending"

export const REVIEWSUCCESS = "review success"

export const REVIEWREJECT = "review reject"

export const SEARCHSUCCESS = "search success"

export const SEARCHREJECT = "search reject"

export const PAIDDILIGENCE = "paid diligence"

export const RECEIVEDDOCUMENTS = "received documents"

export const PAIDCONTRIBUTION = "paid contribution"

export const MONTHLYPAYMENT = "monthly payment"

export const PAYMENTCOMPLETED = "payment completed"

export const TOPAYDILIGENCE = "to pay diligence"

export const TOPAYCONTRIBUTION = "to pay contribution"

export const BEFOREPUBLICVIEW = "before public view"

export const SALE = "sale"

export const RENT = "rent"

export const SHORTLET = "shortlet"

export const EXCLUDE = "exclude"

export const MARKETREPORT = "Market research outlook report"

export const FIRSTAPPROVE = "first approval"

export const SECONDAPPROVE = "second approval"
