import React from "react";

import { Route, Switch, BrowserRouter } from "react-router-dom";

import Loader from "../components/Loader/loader.component";

/**
 * PAGES
 */
import VerifyEmail from "../pages/Auth/VerifyEmail/verify-email.page";
import AdminVerifyEmail from "../pages/Auth/VerifyEmail/admin-verify-email.page";
import SuperAdminVerifyEmail from "../pages/Auth/VerifyEmail/superadmin-verify-email.page";

import ResetPassword from "../pages/Auth/ResetPassword/reset-password.page";


import { AuthProvider } from "../helpers/Auth/authContext";
import { AdminRoute, ProtectedRoute, SuperAdminRoute } from "../helpers/Auth/protectedRoute";


const HomePage = React.lazy(() => import("../pages/HomePage/home.page"));
const AboutPage = React.lazy(() => import("../pages/AboutUs/about.page"));
const FAQPage = React.lazy(() => import("../pages/FAQ/faq.page"));
const ContactPage = React.lazy(() => import("../pages/ContactUs/contact.page"));
const MarketPage = React.lazy(() => import("../pages/Market/market.page"));
const TitlePerfectionPage = React.lazy(() =>
  import("../pages/TitlePerfection/title.page")
);
const LegalSearchPage = React.lazy(() =>
  import("../pages/PropertySearch/legal_search.page")
);
const RegisterPage = React.lazy(() =>
  import("../pages/Auth/Register/register.page")
);
const RegisterAsset = React.lazy(() =>
  import("../pages/Auth/Register/register-asset.page")
);
const RegisterDeveloper = React.lazy(() =>
  import("../pages/Auth/Register/register-developer.page")
);
const RegisterDistress = React.lazy(() =>
  import("../pages/Auth/Register/register-distress.page")
);
const RegisterDsr = React.lazy(() =>
  import("../pages/Auth/Register/register-dsr.page")
);
const RegisterInvester = React.lazy(() =>
  import("../pages/Auth/Register/register-investor.page")
);
const RegisterTenant = React.lazy(() =>
  import("../pages/Auth/Register/register-tenant.page")
);
// const RegisterAdmin = React.lazy(() =>
//   import("../pages/Auth/Register/register-admin.page")
// );
const RegisterSuperAdmin = React.lazy(() =>
  import("../pages/Auth/Register/register-superadmin.page")
);

const NotVerified = React.lazy(() =>
  import("../pages/NotVerified/not-verified.page")
);
const LoginPage = React.lazy(() => import("../pages/Auth/Login/login.page"));

const LoginIndex = React.lazy(() => import("../pages/Auth/Login/login-index.page"));
const SuperAdminLogin = React.lazy(() => import("../pages/Auth/Login/superadmin-login.page"));
const AdminLogin = React.lazy(() => import("../pages/Auth/Login/admin-login.page"));


const InvestorDashboard = React.lazy(() =>
  import("../pages/Dashboards/Investors/investorDashboard.page")
);
const SuperAdminDashboard = React.lazy(() =>
  import("../pages/Dashboards/SuperAdmin/superadmin-dashboard.page")
);
// const VetAdminDashboard = React.lazy(() =>
//   import("../pages/Dashboards/VetAdmin/vetadmin-dashboard.page")
// );
// const PostAdminDashboard = React.lazy(() =>
//   import("../pages/Dashboards/PostAdmin/postadmin-dashboard.page")
// );
const AdminDashboard = React.lazy(() =>
  import("../pages/Dashboards/Admin/admin-dashboard.page")
);

// const AdminDashboard = React.lazy(() =>
//   import("../pages/Dashboards/Admin/admin-dashboard.page")
// );

const AssetOwnerDashboard = React.lazy(() =>
  import("../pages/Dashboards/AssetOwners/asset-owners-dashboard.page")
);
const DeveloperDashboard = React.lazy(() =>
  import("../pages/Dashboards/Developers/dev-dashboard.page")
);
const DSRDashboard = React.lazy(() =>
  import("../pages/Dashboards/DSR/dsr-dashboard.page")
);
const RentDashboard = React.lazy(() =>
  import("../pages/Dashboards/Rents/rent-dashboard.page")
);
const DistressedDashboard = React.lazy(() =>
  import("../pages/Dashboards/Distressed/distressed-dashboard.page")
);
const Risks = React.lazy(() => import("../pages/Risks/risks.page"));
const Outlook = React.lazy(() => import("../pages/Outlook/outlook.page"));


const PartnersPage = React.lazy(() =>
  import("../pages/Partners/partners.page")
);
const TheInvestorPage = React.lazy(() =>
  import("../pages/ThePlatforms/investor.page")
);
const TheAssetOwnerPage = React.lazy(() =>
  import("../pages/ThePlatforms/assetowner.page")
);
const TheDeveloperPage = React.lazy(() =>
  import("../pages/ThePlatforms/developer.page")
);
const TheDSRPage = React.lazy(() =>
  import("../pages/ThePlatforms/dsr.page")
);
const TheDistressedPage = React.lazy(() =>
  import("../pages/ThePlatforms/distressed.page")
);
const TheEasyRentPage = React.lazy(() =>
  import("../pages/ThePlatforms/easyrent.page")
);

const PublicPropertyPage = React.lazy(() =>
  import("../pages/PublicProperties/properties-index.page")
);
const PublicPropertyAllPage = React.lazy(() =>
  import("../pages/PublicProperties/properties-all.page")
);
const SinglePublicPropertyPage = React.lazy(() =>
  import("../pages/PublicProperties/single-property.page")
);

const DsrPropertyPage = React.lazy(() =>
  import("../pages/PublicProperties/dsr_properties.page")
);

//dont allow auth users visit register login:


const Routes = () => (
  <BrowserRouter>
    <React.Suspense fallback={<Loader />}>
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/about" exact component={AboutPage} />
        <Route path="/faq" component={FAQPage} />
        <Route path="/contactus" component={ContactPage} />
        <Route path="/market" component={MarketPage} />
        <Route path="/legal-search" component={LegalSearchPage} />
        <Route path="/title" component={TitlePerfectionPage} />
        <Route path="/register" component={RegisterPage} />
        
        <Route path="/asset_owner/register" component={RegisterAsset} />
        <Route path="/developer/register" component={RegisterDeveloper} />
        <Route path="/distressed_sale/register" component={RegisterDistress} />
        <Route path="/dsr/register" component={RegisterDsr} />
        <Route path="/investor/register" component={RegisterInvester} />
        <Route path="/easy_rent/register" component={RegisterTenant} />
        {/* <Route path="/admin/register" component={RegisterAdmin} /> */}
        <Route path="/superadmin/register" component={RegisterSuperAdmin} />
        <Route path="/not-verified" component={NotVerified} />
        
      {/* Since normal login uses wildcard, the admin login must be placed above it */}
        <Route path="/superadmin/login" component={SuperAdminLogin} />
        <Route path="/admin/login" component={AdminLogin} />

        {/* <Route path="/test-register" component={RegisterPage} /> */}
        <Route path="/login" component={LoginPage} />
        <Route path="/:profile/login" component={LoginIndex} />

        {/* <Route path="/test-login" component={LoginPage} /> */}
        <Route path="/verify/:id/:email/:profile" component={VerifyEmail} />
        <Route path="/admin-verify/:id/:email/:profile" component={AdminVerifyEmail} />
        <Route path="/superadmin-verify/:id/:email/:profile" component={SuperAdminVerifyEmail} />

        <Route path="/forgot-password-reset/:token/:profile/:email" component={ResetPassword} />

        <Route path="/risks" component={Risks} />
        <Route path="/outlook" component={Outlook} />
        <Route path="/partners" component={PartnersPage} />
        <Route path="/investor" component={TheInvestorPage} />
        <Route path="/developer" component={TheDeveloperPage} />
        <Route path="/asset_owner" component={TheAssetOwnerPage} />
        <Route path="/distressed_sale" component={TheDistressedPage} />
        <Route path="/dsr" component={TheDSRPage} />
        <Route path="/easy_rent" component={TheEasyRentPage} />


        <Route path="/properties/public/:id" component={SinglePublicPropertyPage} />
        <Route path="/properties/public" component={PublicPropertyPage} />
        <Route path="/properties/all/public" component={PublicPropertyAllPage} />

        <Route path="/brief/:id" component={DsrPropertyPage} />

        <AuthProvider>

        <AdminRoute
          path="/dashboard/admin"
          component={AdminDashboard}
        />

        <SuperAdminRoute
          path="/dashboard/superadmin"
          component={SuperAdminDashboard}
        />

        <ProtectedRoute
          path="/dashboard/asset_owner"
          component={AssetOwnerDashboard}
        />
        <ProtectedRoute
          path="/dashboard/developer"
          component={DeveloperDashboard}
        />

        <ProtectedRoute
          path="/dashboard/dsr"
          component={DSRDashboard}
        />

        <ProtectedRoute
          path="/dashboard/easy_rent"
          component={RentDashboard}
        />

        <ProtectedRoute
          path="/dashboard/distressed_sale"
          component={DistressedDashboard}
        />

        <ProtectedRoute
          path="/dashboard/investor"
          component={InvestorDashboard}
        />
      </AuthProvider>

      </Switch>
    </React.Suspense>
  </BrowserRouter>
);

export default Routes;
