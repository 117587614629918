import React, { useRef } from "react";

/**
 * Custom Hooks
 */
import useDropdown from "../../customHooks/use-dropdown";
import { Link } from "react-router-dom";
import { Research } from './faq.component';

const HeaderDropdown3 = () => {
  const node = useRef();

  // const [dropdown, toggleDropDown] = useDropdown(node);
  // const onClick = () => toggleDropDown();
  // const dropdownClass = "dropdown-heading";

  return (
    <>
    <span className="header-mobile">
      <Research  />
    </span>
    <div ref={node} className="dropdown">
    <span className="header-desktop">
      <button
        className="btn btn--small btn--uppercase header-navbar__services"
      >
        Research & Articles
      </button>

      <ul className="others style-dropdown">
        <Link to="/risks">
          <li className="dropdown-list">Risks</li>
        </Link>

        <Link to="/outlook">
          <li className="dropdown-list">Articles</li>
        </Link>
      </ul>
      </span>
    </div>
    </>
  );
};

export default HeaderDropdown3;
