import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { Professional } from "../../components/DropDowns/faq.component";

/**
 * Custom Hooks
 */
import useDropdown from "../../customHooks/use-dropdown";

const HeaderDropdown = () => {
  const node = useRef();

  const [dropdown, toggleDropDown] = useDropdown(node);
  const onClick = () => toggleDropDown();

  const dropdownClass = "dropdown-heading";

  return (
    <>
    <span className="header-mobile">
      <Professional  />
    </span>

    <div ref={node} className="dropdown">
    <span className="header-desktop">
      <button
        className="btn btn--small btn--uppercase header-navbar__services"
        onClick={onClick}
      >
        Professional Services
        
      </button>

      <ul className="others style-dropdown">
        <Link to="/legal-search">
          <button className="dropdown-list">Legal Search</button>
        </Link>
        <Link to="/title">
          <button className="dropdown-list">Title Perfection</button>
        </Link>
      </ul>
      </span>
    </div>
    </>
  );
};

export default HeaderDropdown;
